// import { Link, withAssetPrefix } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
// import Button from "./button"
// import placeholder from "./../images/placeholder.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBullseye } from "@fortawesome/free-solid-svg-icons"
import { faCar } from "@fortawesome/free-solid-svg-icons"
import { faUserCheck } from "@fortawesome/free-solid-svg-icons"

const Slagord = ({ siteTitle }) => (
  <div
    className="flex flex-col md:flex-row"
    style={{
      margin: "0",
      color: "white",
      textAlign: "center",
    }}
  >
    <div
      className="column is-one-third is-centered"
      style={{
        background: "#2E2E2E",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faBullseye} size="2x" />

      <h3 style={{ margin: "0.5em" }}>Vårt mål</h3>
      <p>
        Vårt mål är att bemöta varje elev som en individ med egna behov och
        förutsättningar, och anpassa utbildningen därefter.
      </p>
    </div>
    <div
      className="column is-one-third is-centered"
      style={{
        background: "#C42C00",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faCar} size="2x" />
      {/* <img src={placeholder} style={{margin: 0, padding: 0, width: 48}}></img> */}
      <h3 style={{ margin: "0.5em" }}>Våra bilar</h3>
      <p>
        På Albin’s kör vi VW Golf med all den komfort man kan önska sig,
        samtidigt som de är lätta att övningsköra med.
      </p>
    </div>
    <div
      className="column is-one-third is-centered"
      style={{
        background: "#2E2E2E",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faUserCheck} size="2x" />
      {/* <img src={placeholder} style={{margin: 0, padding: 0, width: 48}}></img> */}
      <h3 style={{ margin: "0.5em" }}>Vår erfarenhet</h3>
      <p>
        Erfarna trafiklärare underlättar förstås för alla elever, och förkortar
        tiden det tar att klara av uppkörningen!
      </p>
    </div>
  </div>
)

export default Slagord
