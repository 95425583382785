import React from "react"

const ButtonLink = ({ children }) => (
  <div
    style={{
      minWidth: '5em',
      maxWidth: '20em',
      borderRadius: '4px'
    }}
    className='ButtonLink'
  >
    {children}
  </div>
)


export default ButtonLink
