// import { Link, withAssetPrefix } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
// import Button from "./button"
// import placeholder from "./../images/placeholder.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"

const Reviews = ({ siteTitle }) => (
  <div
    className="thirdie flex m-0 text-white text-left flex flex-col sm:flex-row"
  >
    <div
      className="column is-one-third"
      style={{
        background: "#3E3E3E",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faQuoteLeft} size="2x" />
      <p style={{ marginTop: "0.5em" }}>
        {" "}
        Den bästa trafikskolan som finns! Trafikläraren Amin Soltani är en
        mycket begåvad lärare som kan anpassa sig till alla möjliga situationer
        i trafiken och hjälpa dig bli en mycket bra och trafiksäker förare. Jag
        klarade både teori- och körprovet på första försöket, tack vare Albins
        trafikskola!
      </p>
      <p style={{ opacity: "0.5" }}>- Haval</p>
      <hr style={{ margin: "0" }} />
    </div>

    <div
      className="column is-one-third"
      style={{
        background: "#3E3E3E",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faQuoteLeft} size="2x" />
      <p style={{ marginTop: "0.5em" }}>
        Suverän trafikskola! Fixade körkort och det är mycket tack vare min
        körlärare Amin Soltani. Han har en fantastisk förmåga att se och förstå
        hur en ligger till och vilka moment som behöver finslipas, kombinerat
        med mycket god pedagogik och tålamod.
      </p>
      <p style={{ opacity: "0.5" }}>- Nils</p>
      <hr style={{ margin: "0" }} />
    </div>

    <div
      className="column is-one-third"
      style={{
        background: "#3E3E3E",
        color: "white",
        padding: "2.5em",
      }}
    >
      <FontAwesomeIcon icon={faQuoteLeft} size="2x" />
      <p style={{ marginTop: "0.5em" }}>
        Klarade körkortet idag på första försöket efter drygt tre veckors
        intensivt körande med Amin, och det hade inte gått utan honom. En lärare
        som är motiverad till att hjälpa sina elever. Jag kan inte annat än
        rekommendera!
      </p>

      <p style={{ opacity: "0.5" }}>- Björn</p>
      <hr style={{ margin: "0" }} />
    </div>
  </div>
)

export default Reviews
