import { css } from "@emotion/react"

export const StandardButton = css`
  color: white;
  background-color: var(--primary-color);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  &:hover {
    background-color: var(--primary-hover-color);
  }
`

export const MopedButton = css`
  color: red;
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1rem 0 1rem 0;

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(2deg);
    }
    95% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  display: inline-block;
  animation: wiggle 1.7s infinite;

  &:hover {
    animation: none;
    background-color: #eee;
  }
`

export const BookingButton = css`
  ${StandardButton}
  margin-bottom: 12px;
  margin: 0 auto;
`

export const BookingButtonDisabled = css`
  ${BookingButton}
  opacity: 0.2;
`
